export const pageTransition = {
    in: {
        opacity: 1
    },
    out: {
        opacity: 0
    }
}

export const menuHover = {
    whileHover: {
        // color: '#000000',
        // backgroundColor: '#ffffff'
        fontWeight: '600'
    }
}