import { Container, Row, Col, Accordion } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { pageTransition } from '../animation';
import { feesData, commonData } from '../data/feesData';
import CustomAccordionItem from '../shared/CustomAccordionItem';

function Fees() {
    return (
        <motion.div 
        className="min-vh-100"
        initial={pageTransition.out}
        animate={ pageTransition.in }
        exit={ pageTransition.out }>
            <Container>
                <Row className="justify-content-center">
                    <Col xs={12} lg={8}>
                        <p>
                            Taksa notarialna ustalona jest w Rozporządzeniu Ministra Sprawiedliwości z dnia 28
                            czerwca 2004 roku w sprawie maksymalnych stawek taksy notarialnej, jako procent
                            od wartości umowy bądź kwota stała.
                        </p>
                        <h5>
                            I. Maksymalna stawka taksy notarialnej liczonej od wartości przedmiotu umowy
                            wynosi:
                        </h5>
                        <ol>
                            <li>do 3000 zł – 100 zł</li>
                            <li>powyżej 3000 zł do 10 000 zł – 100 zł + 3% od nadwyżki powyżej 3000 zł</li>
                            <li>powyżej 10 000 zł do 30 000 zł – 310 zł + 2% od nadwyżki powyżej 10 000 zł</li>
                            <li>powyżej 30 000 zł do 60 000 zł – 710 zł + 1% od nadwyżki powyżej 30 000 zł</li>
                            <li>powyżej 60 000 zł do 1 000 000 zł – 1010 zł + 0,4% od nadwyżki powyżej 60 000 zł</li>
                            <li>powyżej 1 000 000 zł do 2 000 000 zł – 4770 zł + 0,2% od nadwyżki powyżej 1 000000 zł</li>
                            <li>
                                powyżej 2 000 000 zł – 6770 zł + 0,25% od nadwyżki powyżej 2 000 000 zł, nie
                                więcej jednak niż 10 000 zł, a w przypadku czynności dokonywanych pomiędzy
                                osobami zaliczonymi do I grupy podatkowej w rozumieniu przepisów ustawy z dnia
                                28 lipca 1983 r. o podatku od spadków i darowizn nie więcej niż 7500 zł
                            </li>
                        </ol>
                        <Accordion>
                            {feesData.map((data, index) => {
                                return (
                                <CustomAccordionItem
                                    key={index}
                                    eventKey={index}
                                    header={data.header}
                                    body={data.body} />
                                );
                            })}
                        </Accordion>
                        <h5>
                            II. Wynagrodzenie stałe, niezależne od wartości przedmiotu czynności notarialnej,
                            przysługuje za sporządzenie aktu notarialnego dokumentującego:
                        </h5>
                        <ol>
                            <li>
                                umowę zbycia nieruchomości, jeżeli w związku z tą umową następuje wypłata
                                świadczeń z tytułu ubezpieczenia społecznego rolników albo renty strukturalnej
                                współfinansowanej ze środków pochodzących z Sekcji Gwarancji Europejskiego
                                Funduszu Orientacji i Gwarancji Rolnej – 600 zł
                            </li>
                            <li>umowę majątkową małżeńską – 400 zł</li>
                            <li>testament – 50 zł</li>
                            <li>testament zawierający zapis zwykły, polecenie lub pozbawienie uprawnionego prawa do zachowku – 150 zł</li>
                            <li>testament zawierający zapis windykacyjny – 200 zł</li>
                            <li>odwołanie testamentu – 30 zł</li>
                            <li>zwolnienie nieruchomości od obciążeń lub zrzeczenia się prawa, jeżeli wartości przedmiotu nie da się określić – 60 zł</li>
                            <li>zrzeczenie się własności nieruchomości lub prawa użytkowania wieczystego – 80 zł</li>
                            <li>
                                pełnomocnictwo – do dokonania jednej czynności 30 zł, natomiast przy
                                czynnościach zawierających umocowanie do
                                dokonania więcej niż jednej czynności – 100 zł
                            </li>
                            <li>oświadczenie o przyjęciu lub odrzuceniu spadku – 50 zł</li>
                        </ol>
                        <Accordion>
                            {commonData.map((data, index) => {
                                return (
                                <CustomAccordionItem
                                    key={index}
                                    eventKey={index}
                                    header={data.header}
                                    body={data.body} />
                                );
                            })}
                            <CustomAccordionItem eventKey={20}
                            header="Czynności związane z aktem poświadczenia dziedziczenia:"
                            data={<>
                                 <p>1. Za sporządzenie aktu poświadczenia dziedziczenia ustawowego lub testamentowego, uzupełniającego aktu poświadczenia dziedziczenia w zakresie spadkobierców dziedziczących gospodarstwo rolne maksymalna stawka wynosi 50 zł.</p>
                                 <ol type='a'>
                                     <li>Za sporządzenie aktu poświadczenia dziedziczenia testamentowego z zapisem windykacyjnym maksymalna stawka wynosi 100 zł.</li>
                                 </ol>
                                 <p>2. Za sporządzenie protokołu dziedziczenia maksymalna stawka wynosi 100 zł.</p>
                                 <ol type='a'>
                                     <li>Za czynności dotyczące europejskiego poświadczenia spadkowego, z wyłączeniem czynności, o których mowa w ust. 2b i 2c oraz § 12, maksymalna stawka wynosi 400 zł.</li>
                                     <li>Za sporządzenie projektu protokołu dziedziczenia maksymalna stawka wynosi 100 zł.</li>
                                     <li>Za sporządzenie protokołu obejmującego oświadczenie o wyrażeniu zgody na spisanie protokołu dziedziczenia zgodnie z jego projektem maksymalna stawka wynosi 50 zł.</li>
                                 </ol>
                                 <p>3. Za sporządzenie protokołu otwarcia i ogłoszenia testamentu maksymalna stawka wynosi 50 zł.</p>
                                 <p>4. Za sporządzenie zaświadczenia o powołaniu wykonawcy testamentu maksymalna stawka wynosi 30 zł.</p>
                                 </>
                            }/>
                            <CustomAccordionItem eventKey={21}
                            header="Poświadczenia"
                            data={<>
                                <p>1. Za poświadczenie własnoręczności podpisu albo odcisku palca osoby niepiśmiennej lub niemogącej pisać:</p>
                                <ol type='a'>
                                    <li>na dokumentach, jeżeli przedmiot jest oznaczony sumą pieniężną – 1/10 maksymalnej stawki, jaka należałaby się,</li>
                                    <p className="mt-3">gdyby dokument sporządzono w formie aktu notarialnego, nie więcej jednak niż 300 zł,</p>
                                    <li>na pełnomocnictwach i innych dokumentach – 20 zł</li>
                                </ol>
                                <p>2. Za poświadczenie zgodności odpisu z okazanym dokumentem, za każdą stronę – 6 zł</p>
                                <p>3. Za poświadczenie czasu okazania dokumentu, za każdą stronę – 6 zł</p>
                                <p>4. Za poświadczenie pozostawania przy życiu:</p>
                                <ol type='a'>
                                    <li>w celu otrzymania emerytury, renty lub innych świadczeń z ubezpieczenia społecznego – 5 zł</li>
                                    <li>w innym celu – 30 zł</li>
                                </ol>
                                <p>5. Za poświadczenie pozostawania osoby w określonym miejscu – 30 zł.</p>
                                </>
                            }/>
                            <CustomAccordionItem eventKey={22}
                            header="Protesty"
                            data={<>
                                <p>Za sporządzenie protestu maksymalna stawka wynosi przy wartości kwoty ulegającej zaprotestowaniu</p>
                                <ol><li>do 1000 zł włącznie – 5 zł</li>
                                <li>powyżej 1000 zł – 5 zł + 0,5% od nadwyżki ponad 1000 zł, nie więcej jednak niż 2500 zł</li></ol>
                                </>
                            }/>
                        </Accordion>
                        <h5>Zwiększenie wynagrodzenia za dokonanie czynności notarialnej poza kancelarią notarialną:</h5>
                        <p>Maksymalna kwota, o którą może być zwiększone wynagrodzenie za dokonanie czynności notarialnej poza kancelarią notarialną, wynosi:</p>
                        <ol>
                            <li>w porze dziennej – 50 zł</li>
                            <li>w porze nocnej oraz w dni wolne od pracy – 100 zł</li>
                        </ol>
                        <p>za każdą godzinę niezbędną do dokonania tej czynności od opuszczenia kancelarii do powrotu.</p>
                    </Col>
                </Row>
            </Container>
        </motion.div>
    );
}

export default Fees;