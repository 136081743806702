import './Contact.css'
import { Container, Row, Col } from "react-bootstrap";
import { motion } from 'framer-motion';
import { pageTransition } from '../animation';
import { FaMobileAlt, FaMapMarkerAlt } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';

function Contact() {
    return (
        <motion.div
        className="min-vh-100"
        initial={pageTransition.out}
        animate={ pageTransition.in }
        exit={ pageTransition.out }
        >
            <Container>
                <Row xs={1} className="align-items-center">
                    <Col id="contact-col">
                        <div>
                            <a href="tel:+48887799678">
                                <FaMobileAlt className="contact-icon" /> 
                                <span>887 799 678</span>
                            </a>
                        </div>
                        <div>
                            <a href="mailto:biuro@bochnia-notariusz.pl">
                                <MdEmail className="contact-icon" />  
                                <span>biuro@bochnia-notariusz.pl</span>
                            </a>
                        </div>
                        <div>
                            <a href="https://www.google.com/maps/place/Kazimierza+Wielkiego+20,+32-700+Bochnia/@49.9675341,20.4274569,3a,53.7y,146.2h,89.58t/data=!3m6!1e1!3m4!1sxB_FIM6OzwBJ5QzqgyirXA!2e0!7i13312!8i6656!4m5!3m4!1s0x47163b4a0913be6d:0xa71f10a2d0c5124!8m2!3d49.967332!4d20.427737">
                                <FaMapMarkerAlt className="contact-icon" /> 
                                <span>Bochnia, ul. Kazimierza Wielkiego 20</span>
                            </a>
                        </div>
                        <p className="text-center pt-3 pb-3">
                            Kancelaria mieści się w centrum Bochni przy ulicy Kazimierza Wielkiego 20. 
                        </p>
                        <p className="text-center pt-3 pb-3">    
                            Czynna jest od poniedziałku go piątku w godzinach od 9:00 do 17:00, a także w innych terminach po uprzednim telefonicznym uzgodnieniu z notariuszem.
                        </p>
                        <p className="text-center pt-3 pb-3">Jeżeli przemawia za tym charakter czynności  lub szczególne okoliczności istnieje także możliwość dokonania czynności notarialnej poza siedzibą kancelarii notarialnej.
                        </p>
                    </Col>
                    <Col>
                        <iframe 
                        title="map"
                        width="100%" 
                        height="600" 
                        frameBorder="0" 
                        scrolling="yes" 
                        marginHeight="0" 
                        marginWidth="0" 
                        src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=pl&amp;q=kazimierza%20wielkiego%2020%20Bochnia+(Kancelaria%20Notarialna)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                    </Col>
                </Row>
            </Container>
        </motion.div>
    );
}

export default Contact;