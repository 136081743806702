import './Documents.css';

import { documentsData, documentsText } from '../data/documentsData';
import { pageTransition } from '../animation';

import { motion } from 'framer-motion'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import CustomAccordionItem from '../shared/CustomAccordionItem';

function Documents() {
  return (
    <motion.div
      className="min-vh-100"
      initial={pageTransition.out}
      animate={ pageTransition.in }
      exit={ pageTransition.out }
    >
        <Container>
            <Row>
                <Col>
                    <p className="text-center">
                        {documentsText.description}
                    </p>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col lg={8}>
                    <Accordion>
                        {documentsData.map((data, index) => {
                            return (
                            <CustomAccordionItem
                                key={index}
                                eventKey={index}
                                header={data.header}
                                body={data.body} />
                            );
                        })}
                    </Accordion>
                </Col>
            </Row>
        </Container>
    </motion.div>
  );
}

export default Documents;
