import './Home.css';

import Film from '../assets/film.mp4';
import Logo from '../assets/logo.png';

import { motion } from 'framer-motion';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import SubMenu from '../submenu/SubMenu';
import About from '../about/About';
import { pageTransition } from '../animation';

import { FiChevronsDown } from 'react-icons/fi';

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });

function Home() {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  return (
    <motion.div
    initial={pageTransition.out}
    animate={ pageTransition.in }
    exit={ pageTransition.out }>
      <Container className="home-container" fluid>
          <video className="background-video" playsInline autoPlay loop muted>
              <source src={Film} type="video/mp4"/>
          </video>
          <Image src={Logo} className="logo px-3" fluid />
          {/* <Row className="align-items-center text-center h-75">
            <Col>
              <Image src={Logo} className="pt-5 mt-5" width="500" fluid />
            </Col>
          </Row> */}
          {/* <Row className="text-center h-25">
            <Col>
              <motion.div onClick={scrollToContent}
                          className="mb-5 d-none d-md-block" 
                          animate={{
                            y: [80, 40, 80]
                          }}
                          transition={{
                            repeat: Infinity,
                            duration: 2
                          }}>
                <FiChevronsDown id="arrow-icon" />
              </motion.div>
            </Col>
          </Row> */}
      </Container>
      <About id="about"/>
      <SubMenu />
    </motion.div>
  );
}

export default Home;
