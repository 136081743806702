import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import CustomCard from '../shared/CustomCard';

function SubMenu() {
  return (
    <Container>
        <Row xs={1} sm={2} lg={3}>
            <Col>
                <CustomCard address="/czynności-notarialne"
                            title="Czynności Notarialne"
                            text="Czynności notarialne powinny być sporządzone w sposób zrozumiały i przejrzysty, a Notariusz"/>
            </Col>
            <Col>
              <CustomCard address="/wymagane-dokumenty"
                          title="Wymagane Dokumenty"
                          text="Przedstawiamy wykaz podstawowych dokumentów koniecznych do dokonania czynności notarialnych"/>
            </Col>
            <Col>
              <CustomCard address="/opłaty-notarialne"
                          title="Opłaty Notarialne"
                          text="Taksa notarialna ustalona jest w Rozporządzeniu Ministra Sprawiedliwości z dnia 28 czerwca 2004"/>
            </Col>
            <Col lg={6}>
              <CustomCard address="przydatne-linki"
                          title="Przydatne Linki"
                          text="Elektroniczne Księgi Wieczyste, Krajowy Rejestr Sądowy, Centralna Ewidencja i Informacja"/>
            </Col>
            <Col lg={6}>
              <CustomCard address="kontakt"
                          title="Kontakt"
                          text="Kancelaria Notarialna znajduje się w Bochni przy ul. Kazimierza Wielkiego 20"/>
            </Col>
        </Row>
    </Container>
  );
}

export default SubMenu;
