import './Menu.css';

import Logo from './logo2.png';

import { Container, Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Menu() {

  document.addEventListener("DOMContentLoaded", function(){

    const el_autohide = document.querySelector('.autohide');
  
    if(el_autohide){
      var last_scroll_top = 0;
      window.addEventListener('scroll', function() {
            let scroll_top = window.scrollY;
           if(scroll_top < last_scroll_top) {
                el_autohide.classList.remove('scrolled-down');
                el_autohide.classList.add('scrolled-up');
            }
            else {
                el_autohide.classList.remove('scrolled-up');
                el_autohide.classList.add('scrolled-down');
            }
            last_scroll_top = scroll_top;
      }); 
      // window.addEventListener
    }
    // if
  
  });

  return (
    <>
    <Navbar id="custom-navbar" className="autohide d-none d-lg-block" variant="dark" expand="xl" fixed="top">
      <Container>
        <Navbar.Brand>
          <img src={Logo} width="20" className="d-inline-block align-top" alt="Logo"></img>
        </Navbar.Brand>
        <Navbar.Toggle className="ms-auto" aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">
            <Link className="custom-nav-link" to="/"><span>Strona główna</span></Link>
            <Link className="custom-nav-link" to="/czynności-notarialne"><span>Czynności Notarialne</span></Link>
            <Link className="custom-nav-link" to="/wymagane-dokumenty"><span>Wymagane Dokumenty</span></Link>
            <Link className="custom-nav-link" to="/opłaty-notarialne"><span>Opłaty Notarialne</span></Link>
            <Link className="custom-nav-link" to="/przydatne-linki"><span>Przydatne Linki</span></Link>
            <Link className="custom-nav-link" to="/kontakt"><span>Kontakt</span></Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    <div className="d-block d-lg-none text-center" id="phone-container-logo">
      <Link to="/">
        <img src={Logo} width="25" className="py-3" alt="Logo"></img>
      </Link>
    </div>
    </>
  );
}

export default Menu;
