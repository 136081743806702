import './App.css';

import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';

import { AnimatePresence } from 'framer-motion';

import Home from './home/Home';
import Activities from './activities/Activities';
import Documents from './documents/Documents';
import Fees from './fees/Fees';
import Links from './links/Links';
import Contact from './contact/Contact';

function App() {
  const location = useLocation();
  return (
    <AnimatePresence exitBeforeEnter>
      <Switch location={location} key={location.pathname}>
        <Route path="/" exact component={Home} />
        <Route path="/czynności-notarialne" component={Activities} />
        <Route path="/wymagane-dokumenty" component={Documents} />
        <Route path="/opłaty-notarialne" component={Fees} />
        <Route path="/przydatne-linki" component={Links} />
        <Route path="/kontakt" component={Contact} />
      </Switch>
    </AnimatePresence>
  );
}

export default App;
