export const documentsText = {
    description: "Poniżej przedstawiamy wykaz podstawowych dokumentów koniecznych do dokonania czynności notarialnych:"
}

export const documentsData = [
    {
        header: "Akt poświadczenia dziedziczenia",
        body: [
            "akt zgonu spadkodawcy",
            "testament spadkodawcy, jeżeli został sporządzony",
            "akt stanu cywilnego spadkobierców: akty urodzenia lub akty małżeństwa (kobiety zamężne)",
            "nr PESEL zmarłego (do wglądu dowód osobisty zmarłego, bądź zaświadczenie o numerze PESEL)"
        ]
    },
    {
        header: "Pełnomocnitwo do sprzedaży nieruchomości",
        body: [
            "dane osobowe mocodawcy",
            "dane osobowe pełnomocnika (imiona, nazwisko, imiona rodziców, PESEL, adres zameldowania)",
            "opis nieruchomości, najlepiej – numer księgi wieczystej"
        ]
    },
    {
        header: "Sprzedaż działki",
        body: [
            "wskazanie numeru księgi wieczystej",
            "wskazanie podstawy nabycia nieruchomości przez sprzedającego, czyli jeden z wymienionych dokumentów, tj.: wypis aktu notarialnego umowy sprzedaży, darowizny lub prawomocne postanowienie sądu o stwierdzeniu nabycia spadku lub akt poświadczenia dziedziczenia, w przypadku spadkobrania łącznie z zaświadczeniem urzędu skarbowego o wywiązaniu się spadkobierców z obowiązku podatkowego",
            "dane osobowe kupujących i sprzedających – imiona, nazwiska, imiona rodziców, stan cywilny, nr dowodu osobistego lub paszportu, PESEL, adres zamieszkania",
            "wartość rynkowa działki (cena sprzedaży)",
            "wypis z miejscowego planu zagospodarowania przestrzennego o przeznaczeniu gruntu lub zaświadczenie o jego braku",
            "zaświadczenie czy nieruchomość jest objęta planem urządzenia lasu lub decyzją o której mowa w art. 19 ust. 3 ustawy o lasach",
            "zaświadczenie czy nieruchomość jest objęta obszarem rewitalizacji",
            "wypis z rejestru gruntów wraz z wyrysem z mapy ewidencyjnej, gdy będzie wydzielana działka do nowej księgi wieczystej",
            "ostateczna decyzja zatwierdzająca podział nieruchomości, gdy następuje podział",
            "oświadczenie banku o ustanowieniu hipoteki oraz umowa kredytowa (do wglądu) – w przypadkach kiedy finansowanie zakupu lokalu jest w całości bądź w części ze środków pochodzących z kredytu bankowego, a ustanowienie hipoteki nastąpi w akcie notarialnym"
        ]
    },
    {
        header: "Sprzedaż lub darowizna lokalu z księga wieczystą",
        body: [
            "wskazanie numeru księgi wieczystej",
            "wskazanie podstawa nabycia, czyli jeden z wymienionych dokumentów, tj.: wypis aktu notarialnego umowy sprzedaży, darowizny lub umowy ustanowienia odrębnej własności lokalu lub prawomocne postanowienie sądu o stwierdzeniu nabycia spadku lub akt poświadczenia dziedziczenia, w przypadku spadkobrania łącznie z zaświadczeniem urzędu skarbowego o wywiązaniu się spadkobierców z obowiązku podatkowego",
            "dane osobowe kupujących i sprzedających, obdarowanych (imiona, nazwisko, imiona rodziców, stan cywilny, nr dowodu osobistego lub paszportu, PESEL, adres zamieszkania)",
            "wartość rynkowa lokalu"
        ]
    },
    {
        header: "Testament",
        body: [
            "dane osobowe sporządzającego testament",
            "dane osoby powoływanej do spadku (imiona, nazwisko, imiona rodziców, PESEL, adres zameldowania, data urodzenia)"
        ]
    },
    {
        header: "Umowa majątkowa małżeńska",
        body: [
            "odpis skrócony aktu małżeństwa",
            "dane osobowe małżonków"
        ]
    }
]