import './About.css'
import { Container, Row, Col, Image } from 'react-bootstrap';
import { aboutData } from '../data/aboutData';
import Woman from './woman.jpg';

function About() {
  return (
      <Container className="pb-5 pt-3">
          <Row xs={1} lg={2} className="align-items-center">
              <Col className="about-data text-center pt-3 pb-3">
                <p>{aboutData}</p>
                <p>Notariusz Kinga Jaworek jako osoba zaufania publicznego pełni funkcję notariusza i prowadzi własną kancelarię notarialną na podstawie decyzji Ministra Sprawiedliwości.
                Wszelkie informacje dotyczące czynności notarialnych udzielane są przez notariusza bezpłatnie.
                </p>
              </Col>
              <Col className="text-center">
                <Image className="shadow-lg" src={Woman} width="500" id="woman-photo" fluid />
              </Col>
          </Row>
      </Container>
  );
}

export default About;
