export const feesData = [
    {
        header: "1/2 stawki maksymalnej jest należna za sporządzenie aktu notarialnego dokumentującego",
        body: [
            "umowę zobowiązującą, zawartą pod warunkiem lub z zastrzeżeniem terminu",
            "umowę przenoszącą własność lub użytkowanie wieczyste, w wykonaniu umowy zobowiązującej",
            "umowę sprzedaży nieruchomości rolnej z Zasobu Własności Rolnej Skarbu Państwa,",
            "umowę sprzedaży lokalu mieszkalnego lub nieruchomości gruntowej zabudowanej jednorodzinnym budynkiem mieszkalnym, dokonywanej przez Skarb Państwa, jednostki samorządu terytorialnego lub inne podmioty na podstawie ustaw przyznających nabywcom tych lokali lub nieruchomości bonifikaty od ceny",
            "umowę zbycia spółdzielczego własnościowego prawa do lokalu",
            "umowę zbycia własnościowego spółdzielczego prawa do lokalu mieszkalnego, spółdzielczego prawa do lokalu użytkowego, prawa do domu jednorodzinnego w spółdzielni mieszkaniowej (prawa do lokalu mieszkalnego w domu budowanym przez spółdzielnię mieszkaniową w celu przeniesienia jego własności na członka)",
            "umowę zbycia ekspektatywy spółdzielczego własnościowego prawa do lokalu, odrębnej własności lokalu lub domu jednorodzinnego w trybie ustawy z dnia 15 grudnia 2000 r. o spółdzielniach mieszkaniowych (Dz. U. z 2013 r. poz. 1222, z 2015 r. poz. 201 oraz z 2017 r. poz. 1442 i 1596)",
            "umowę ustanowienia odrębnej własności lokalu lub przeniesienia własności domu jednorodzinnego z prawem do gruntu, zawartych w wykonaniu umów o budowę lokalu lub domu, w trybie ustawy z dnia 15 grudnia 2000 r. o spółdzielniach mieszkaniowych",
            "umowę przeniesienia własności lokalu lub domu jednorodzinnego z prawem do gruntu, przez spółdzielnię mieszkaniową",
            "umowę przeniesienia własności, oddania w użytkowanie wieczyste lub przeniesienia prawa użytkowania wieczystego działki budowlanej na rzecz spółdzielni mieszkaniowej w trybie art. 35 ustawy z dnia 15 grudnia 2000 r. o spółdzielniach mieszkaniowych",
            "ustanowienie hipoteki, z zastrzeżeniem § 7 ust. 1 pkt 1",
            "przebieg licytacji lub przetargu",
            "przyjęcie na przechowanie pieniędzy w walucie polskiej lub obcej",
            "umowę zawieraną na podstawie przepisu art. 9 ustawy z dnia 24 czerwca 1994 r. o własności lokali (Dz. U. z 2015 r. poz. 1892 oraz z 2017 r. poz. 1529)",
            `umowę darowizny lokalu stanowiącego odrębną nieruchomość, jeżeli umowa jest
            zawierana pomiędzy osobami zaliczanymi do I grupy podatkowej w rozumieniu
            przepisów ustawy z dnia 28 lipca 1983 r. o podatku od spadków i darowizn, a
            nabywcy są uprawnieni do skorzystania z ulgi, o której mowa w art. 16 ustawy z dnia
            28 lipca 1983 r. o podatku od spadku i darowizn`,
            "umowę deweloperską",
            "umowę ustanowienia odrębnej własności lokalu mieszkalnego i przeniesienia własności tego lokalu na nabywcę, w tym w wykonaniu umowy deweloperskiej",
            `umowę sprzedaży nieruchomości gruntowej, stanowiącej działkę budowlaną w
            rozumieniu art. 2 pkt 12 ustawy z dnia
            27 marca 2003 r. o planowaniu i zagospodarowaniu przestrzennym (Dz. U. z 2017 r.
            poz. 1073 i 1566)`,
            `umowę przeniesienia na nabywcę własności nieruchomości zabudowanej
            domem jednorodzinnym lub prawa użytkowania wieczystego nieruchomości
            gruntowej i własności domu jednorodzinnego na niej posadowionego, w tym
            w wykonaniu umowy deweloperskiej`,
            "umowę sprzedaży lokalu mieszkalnego"
        ]
    },
    {
        header: "1/4 stawki maksymalnej jest należna za sporządzenie projektu aktu notarialnego obejmującego umowę oraz za sporządzenie aktu notarialnego dokumentującego",
        body: [
            "ustanowienie hipoteki w celu zabezpieczenia kredytu bankowego udzielonego na budownictwo mieszkaniowe, zakup domu mieszkalnego lub lokalu mieszkalnego albo na działalność gospodarczą",
            "losowanie nagrody",
            "potwierdzenie oświadczenia woli zawartego w innym akcie notarialnym"
        ]
    },
    {
        header: "1/10 stawki maksymalnej  jest należna za sporządzenie aktu notarialnego dokumentującego",
        body: [
            "oświadczenie o przystąpieniu do spółki z ograniczoną odpowiedzialnością lub objęciu udziałów w tej spółce",
            `oświadczenie zawierające zgodę osób przystępujących do spółki akcyjnej na
            zawiązanie spółki, na brzmienie statutu i na objęcie akcji, wyrażoną w innym akcie niż
            akt obejmujący statut`
        ]
    }
]

export const commonData = [
    {
        header: "Protokoły notarialne",
        body: [
            "za sporządzenie protokołu zgromadzenia wspólników spółki z ograniczoną odpowiedzialnością lub walnego zgromadzenia spółdzielni – 750 zł",
            "za sporządzenie protokołu walnego zgromadzenia akcjonariuszy – 1100 zł"
        ]
    },
    {
        header: "Wypisy/odpisy/wyciągi",
        body: [
            "Za sporządzenie wypisu, odpisu lub wyciągu z akt notarialnych lub innego dokumentu maksymalna stawka wynosi 6 zł za każdą rozpoczętą stronę"
        ]
    },
    {
        header: "Doręczenia",
        body: [
            "Za doręczenie stronie przeciwnej oświadczenia wniesionego ustnie do protokołu albo wręczonego lub przesłanego notariuszowi maksymalna stawka wynosi 40 zł"
        ]
    }
]