import { Container, Row, Col } from 'react-bootstrap';
import CustomCard from '../shared/CustomCard';
import { motion } from 'framer-motion';
import { pageTransition } from '../animation';

function Links() {
    return (
        <motion.div 
        className="min-vh-100"
        initial={pageTransition.out}
        animate={ pageTransition.in }
        exit={ pageTransition.out }>
            <Container>
                <Row xs={1} sm={2} lg={3}>
                    <Col>
                        <CustomCard address="https://ekw.ms.gov.pl/eukw_ogol/menu.do?fbclid=IwAR2KjGjfqmWVWvU-yUsWKST4DycElMrsdsJSmMMPypoLWVnud2f13avcHDI"
                                    title="Elektroniczne Księgi Wieczyste"
                        />
                    </Col>
                    <Col>
                        <CustomCard address='https://ekw.ms.gov.pl/eukw_ogol/menu.do?fbclid=IwAR2KjGjfqmWVWvU-yUsWKST4DycElMrsdsJSmMMPypoLWVnud2f13avcHDI'
                                    title="Krajowy Rejestr Sądowy"
                        />
                    </Col>
                    <Col>
                        <CustomCard address='https://www.gov.pl/web/gov/centralna-ewidencja-dzialalnosci-gospodarczej---portal-informacyjny?fbclid=IwAR3InsESxXJlp55kW2TcMrhk9pUlDyDTjP9uEVBB2ZhToSQGur4gECkRTK0'
                                    title="Centralna Ewidencja i Informacja o Działalności Gospodarczej"
                        />
                    </Col>
                    <Col>
                        <CustomCard address='https://www.bochnia.sr.gov.pl/?fbclid=IwAR2oASIl_iy1vDVT75wkzU5nh4ySHR1h_8uOC2XBb0LSoJbNxTH3FFjwJRo'
                                    title="Sąd Rejonowy w Bochni"
                        />
                    </Col>
                    <Col>
                        <CustomCard address='https://isap.sejm.gov.pl/?fbclid=IwAR2bCPvKaN2VvUFSv3H2hV9co6DzDWdDrpsjWvwevJzmUJeJiBFWTEA0iFc'
                                    title="Internetowy System Aktów Prawnych"
                        />
                    </Col>
                    <Col>
                        <CustomCard address='http://www.kin.pl/strona-glowna'
                        title="Krakowska Izba Notarialna"
                        />
                    </Col>
                </Row>
            </Container>
        </motion.div>
    );
}

export default Links;