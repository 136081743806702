import './Footer.css';

import { Col, Container, Row } from 'react-bootstrap';
import { FaMobileAlt, FaMapMarkerAlt } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';

function Footer() {
  return (
    <Container id="outer-footer-container" fluid>
      <Container>
          <Row xs={1} md={3} className="text-center">
              <Col>
                  <FaMobileAlt className="footer-icon" /> 
                  <a href="tel:+48887799678">887 799 678</a>
              </Col>
              <Col>
                  <MdEmail className="footer-icon" /> 
                  <a href="mailto:biuro@bochnia-notariusz.pl">biuro@bochnia-notariusz.pl</a>
              </Col>
              <Col>
                  <FaMapMarkerAlt className="footer-icon" /> 
                  <a href="https://www.google.com/maps/place/Kazimierza+Wielkiego+20,+32-700+Bochnia/@49.9675341,20.4274569,3a,53.7y,146.2h,89.58t/data=!3m6!1e1!3m4!1sxB_FIM6OzwBJ5QzqgyirXA!2e0!7i13312!8i6656!4m5!3m4!1s0x47163b4a0913be6d:0xa71f10a2d0c5124!8m2!3d49.967332!4d20.427737">Bochnia, ul. Kazimierza Wielkiego 20</a>
              </Col>
          </Row>
      </Container>
    </Container>
  );
}

export default Footer;
