import { Accordion, Card, useAccordionButton } from 'react-bootstrap';

import { motion } from 'framer-motion';

function CustomToggle({children, eventKey}) {
  const decoratedOnClick = useAccordionButton(eventKey);

  return (
    <motion.button
      className="shadow p-3 rounded-pill border"
      whileHover={{
        scale: 1.25,
        backgroundColor: '#003e2f',
        color: '#ffffff'
      }}
      // whileFocus={{
      //   backgroundColor: '#003e2f',
      //   color: '#ffffff'
      // }}
      type="button"
      onClick={decoratedOnClick}
      style={{
        border: 'none',
        backgroundColor: '#ffffff',
        width: '100%',
      }}>
      {children}
    </motion.button>
  );
}

function CustomAccordionItem(props) {
  if(props.body != null) {
    return (
      <Card className="m-3" bsPrefix="none">
        <Card.Header className="text-center border-0 bg-transparent fs-4">
          <CustomToggle eventKey={props.eventKey}>{props.header}</CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey={props.eventKey}>
          <Card.Body>
            <ul className="py-3">
              {props.body.map((data, index) => {
                return (
                  <li key={index}>{data}</li>
                );
              })}            
            </ul>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  }
  else {
    return (
      <Card className="m-3" bsPrefix="none">
        <Card.Header className="text-center border-0 bg-transparent fs-4">
          <CustomToggle eventKey={props.eventKey}>{props.header}</CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey={props.eventKey}>
          <Card.Body>
            {props.data}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  }
}

export default CustomAccordionItem;
