import './CustomCard.css';
import Card from 'react-bootstrap/Card';
import { FaFileSignature, FaRegListAlt, FaMoneyBill, FaPaperclip, FaUserEdit, FaRegAddressBook, FaBook } from 'react-icons/fa';
import { BsArrowRight } from 'react-icons/bs';
import { TiDocumentText, TiThSmallOutline } from 'react-icons/ti';
import { AiOutlineBank, AiFillHdd, AiOutlineEdit } from 'react-icons/ai';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

function ChooseIcon(props) {
  switch(props.title) {
    case 'Czynności Notarialne':
      return <FaFileSignature className="display-2" />
    case 'Wymagane Dokumenty':
      return <FaRegListAlt className="display-2" />
    case 'Opłaty Notarialne':
      return <FaMoneyBill className="display-2" />
    case 'Przydatne Linki':
      return <FaPaperclip className="display-2" />
    case 'O Mnie':
      return <FaUserEdit className="display-2" />
    case 'Kontakt':
      return <FaRegAddressBook className="display-2" />
    case 'Elektroniczne Księgi Wieczyste':  
      return <FaBook className="display-2" />
    case 'Krajowy Rejestr Sądowy':  
      return <TiDocumentText className="display-2" />
    case 'Centralna Ewidencja i Informacja o Działalności Gospodarczej':  
      return <TiThSmallOutline className="display-2" />
    case 'Sąd Rejonowy w Bochni':  
      return <AiOutlineBank className="display-2" />  
    case 'Internetowy System Aktów Prawnych':  
      return <AiFillHdd className="display-2" />
    case 'Krakowska Izba Notarialna':  
      return <AiOutlineEdit className="display-2" />
    default:
      return <FaBook className="display-2" />
  }
}

function CustomCard(props) {
  if(props.text != null) {
    return (
      <motion.div 
        whileTap={{
          scale: 0.9
        }}>
        <Card className="m-3 shadow-lg custom-card">
          <Card.Body>
            <Card.Title className="text-center">
            <ChooseIcon title={props.title} />
              <h2 className="p-3 custom-card-title">{props.title}</h2>
            </Card.Title>
            <Card.Text className="custom-card-text">
                {props.text}...
            </Card.Text>
          </Card.Body>
          <Card.Footer bsPrefix="custom-card-footer">
            <Link to={props.address}>
              <button className="w-100 custom-card-button">
                <BsArrowRight />
              </button>
            </Link>
          </Card.Footer>
        </Card>
      </motion.div>
    );
  }
  else {
    return (
      <motion.div 
        whileTap={{
          scale: 0.9
        }}>
        <Card className="m-3 shadow-lg custom-card custom-card-link">
          <Card.Body>
            <Card.Title className="text-center custom-card-title-link">
            <ChooseIcon title={props.title} />
              <div className="title-link">
                <h2 className="p-3 custom-card-title">{props.title}</h2>
              </div>
            </Card.Title>
          </Card.Body>
          <Card.Footer bsPrefix="custom-card-footer">
              <a href={props.address} target="_blank" className="w-100 custom-card-button d-block text-center" rel="noreferrer">
                <BsArrowRight />
              </a>
          </Card.Footer>
        </Card>
      </motion.div>
    );    
  }
}

export default CustomCard;
