import './Activities.css';
import { Container, Row, Col } from 'react-bootstrap';
import { motion } from 'framer-motion'
import { pageTransition } from '../animation';

function Activities() {
  return (
    <motion.div
      className="min-vh-100"
      initial={pageTransition.out}
      animate={ pageTransition.in }
      exit={ pageTransition.out }
    >
      <Container>
        <Row className="justify-content-center">
          <Col lg={8}>
            <p>
              Art. 1. § 1. Notariusz jest powołany do dokonywania czynności, którym strony są obowiązane lub 
              pragną nadać formę notarialną (czynności notarialnych).
            </p>
            <p>
              Art. 2. § 1. Notariusz w zakresie swoich uprawnień, o których mowa w art. 1, działa jako osoba
              zaufania publicznego, korzystając z ochrony przysługującej funkcjonariuszom publicznym
            </p>
            <p>
              Prawo o notariacie (Dz.U. 1991 Nr 22 poz. 91)
            </p>
            <p>
              Notariusz czuwa nad należytym zabezpieczeniem interesów każdego z uczestników czynności
              notarialnej oraz osób, które mogą być dotknięte ich skutkami prawnymi. Przed ostatecznym
              dokonaniem czynności notarialnej zalecamy konsultację z kancelarią, w celu skompletowania
              wszystkich niezbędnych dokumentów. Dzięki temu będzie można bez przeszkód przeprowadzić
              nawet zawiłą czynność notarialną w ustalonym przez strony terminie. Konsultacje takie oraz
              porady prawne w związku z zamierzonymi czynnościami notarialnymi są nieodpłatne.
            </p>
            <p>
              Czynności notarialnych dokonuje się w języku polskim. Na żądanie strony notariusz może
              dokonać dodatkowo tej czynności w języku obcym, jednak w takim przypadku niezbędna jest
              obecność tłumacza przysięgłego.
            </p>
            <p>
              Rodzaje czynności notarialnych:
            </p>
            <p>
              Zgodnie z art 79 ustawy z dnia 14 lutego 1991 r. – Prawo o notariacie, notariusz:
            </p>
            <ul>
              <li>sporządza akty notarialne</li>
              <li>sporządza akty poświadczenia dziedziczenia</li>
              <li>sporządza poświadczenia</li>
              <li>doręcza oświadczenia</li>
              <li>spisuje protokoły</li>
              <li>sporządza protesty weksli i czeków</li>
              <li>
                przyjmuje na przechowanie pieniądze, papiery wartościowe, dokumenty, dane na
                informatycznym nośniku danych, o którym mowa w przepisach o informatyzacji
                działalności podmiotów realizujących zadania publiczne
              </li>
              <li>sporządza wypisy, odpisy i wyciągi dokumentów</li>
              <li>sporządza, na żądanie stron, projekty aktów, oświadczeń i innych dokumentów</li>
            </ul>
            <p>
              Czynności notarialne powinny być sporządzone w sposób zrozumiały i przejrzysty, a Notariusz
              jest obowiązany czuwać nad należytym zabezpieczeniem praw i słusznych interesów wszystkich
              stron umowy oraz innych osób, dla których czynność ta może powodować skutki prawne.
            </p>
          </Col>
        </Row>
      </Container>
    </motion.div>
  );
}

export default Activities;
